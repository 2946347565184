(function ($) {
  Drupal.behaviors.bannerCarouselV1 = {
    attach: function (context) {
      var $template = $('.js-banner__carousel__formatter', context);
      var $navBanner = $('.js-nav--banner', context);
      var $pageWrapper = $('.page-wrapper', context);

      // Don't even bother setting up the banner carousel if the
      // cookie has already been set to hide it. Just hide it.
      if (generic.cookie('HIDE_OFFER_BANNER') && Unison.fetch.now().name !== 'large') {
        $navBanner.addClass('hidden');

        return;
      }

      $template.each(function (index, el) {
        var self = this;
        var speed = $(self).data('speed') * 1000;
        var $closeButton = $('.js-banner-carousel-close', self);
        var $promos = $('.js-banner__carousel__item', self);
        var cookieProps = {};

        cookieProps.expires = 1 / 24;

        $pageWrapper.addClass('page-wrapper--with-offer-banner');

        $closeButton.on('click', function () {
          $navBanner.addClass('hidden');
          $pageWrapper.removeClass('page-wrapper--with-offer-banner');
          generic.cookie('HIDE_OFFER_BANNER', true, cookieProps);
        });

        if ($promos.length < 2) {
          return;
        }

        setInterval(function () {
          // re-select .js-banner__carousel__item rather than use $promos because
          // we're changing the order.
          $('.js-banner__carousel__item', self)
            .first()
            .slideUp(function () {
              $(this).appendTo($(this).parent()).css({
                overflow: '',
                display: ''
              });
            });
        }, speed);
      });
    }
  };
})(jQuery);
